<template>
  <el-dialog
    custom-class="ql-dialog eduDialog"
    :title="isEdit ? '编辑教育局' : '新增教育局'"
    :visible.sync="visible"
    width="582px"
    @closed="handleClose"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="88px">
      <el-form-item label="教育局编号" prop="educationKey" size="small">
        <el-input v-model.trim="formData.educationKey" placeholder="请输入教育局编号" :disabled="isEdit"></el-input>
      </el-form-item>
      <el-form-item label="教育局名称" prop="educationName" size="small">
        <el-input v-model.trim="formData.educationName" placeholder="请输入教育局名称"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input } from 'element-ui';
import { saveRegisterEducation } from '@/api/back-stage';
export default {
  name: 'eduDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateEducationKey = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('教育局编号不能为空'));
        return;
      }
      callback();
    };
    const validateEducationName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('教育局名称不能为空'));
        return;
      }
      callback();
    };
    return {
      rules: {
        educationKey: [
          {
            validator: validateEducationKey,
            trigger: 'blur',
          },
        ],
        educationName: [
          {
            validator: validateEducationName,
            trigger: 'blur',
          },
        ],
      },
      formData: {
        educationKey: '',
        educationName: '',
        educationId: null,
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveRegisterEducation({
            educationKey: this.formData.educationKey,
            educationName: this.formData.educationName,
            id: this.isEdit ? this.formData.educationId : undefined,
          }).then(
            (res) => {
              if (res && res.result === 0) {
                this.$emit('success', this.formData);
              } else {
                this.$message.error(res.msg);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
    },

    handleClose() {
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.eduDialog {
}
</style>

// methods
import { getRegisterEducation } from '@/api/back-stage';
// components
import { Button, Table, TableColumn, Input } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import searchInput from '@/components/searchInput';
import eduDialog from './components/eduDialog';

export default {
  name: 'registerEducationBureau',
  props: {},
  data() {
    return {
      loading: false,
      searchValue: '',
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      isEdit: false,
      editIndex: -1,
      visible: false,
      formData: {
        resourceName: '',
        resourceAddress: '',
      },
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    backStageHeader,
    qlPagination,
    searchInput,
    eduDialog,
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getRegisterEducation();
    },
    getRegisterEducation(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getRegisterEducation({
        pageIndex,
        pageSize,
        educationNameFuzzy: this.searchValue,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data || [];
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    handleAddNew() {
      this.formData = {
        educationKey: '',
        educationName: '',
        educationId: null,
      };
      this.isEdit = false;
      this.visible = true;
    },

    handleSuccess(item) {
      if (this.isEdit && this.editIndex >= 0) {
        this.listData.list[this.editIndex].educationName = item.educationName;
      } else {
        this.getRegisterEducation(true);
      }
      this.visible = false;
    },

    handleEdit(val, index) {
      const { id, educationKey, educationName } = val;
      this.isEdit = true;
      this.editIndex = index;
      this.formData = {
        educationKey: educationKey,
        educationName: educationName,
        educationId: id,
      };
      this.visible = true;
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getRegisterEducation(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getRegisterEducation();
    },

    handleChange() {
      this.getRegisterEducation(true);
    },
  },
};
